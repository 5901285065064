import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import './About.css';
import GlobalNav from '../components/GlobalNav';

function About() {
    return (
        <Container fluid>
            <Row noGutters>
                <GlobalNav />
            </Row>
            <Row className="mt-3">
                <Col>
                    <Card bg="dark" text="light" className="text-center">
                        <Card.Img variant="top" src="images/Brittany.jpg" />
                        <Card.Body>
                            <Card.Title className="nameTitle">Britt</Card.Title>
                            <Card.Text>
                                My name is Britt Cassidy and my pronouns are
                                they/them. I know that design is an incredible
                                tool that lets us foster real human connection,
                                and I specialize in bringing that to my work. My
                                skills include Adobe XD, Illustrator, Photoshop,
                                creating dazzling user-focused prototypes, and
                                playing well with others. <p />
                                In my free time I enjoy creative writing,
                                playing games of all sorts (my favorites are DND
                                and Animal Crossing) and consuming video essays
                                on YouTube on a range of topics. I love learning
                                new things all the time.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card bg="dark" text="light" className="text-center">
                        <Card.Img variant="top" src="images/Michael.jpg" />
                        <Card.Body>
                            <Card.Title className="nameTitle">
                                Michael
                            </Card.Title>
                            <Card.Text>
                                I am a 40 yr. veteran full-stack software
                                architect / engineer with practical experience
                                in many different industries and platforms. I
                                strive to design good software that is low
                                maintenance and meets the user's needs without
                                over-engineering. When I'm not busy doing real
                                work, I enjoy science fiction tv and all sorts
                                of video games. <p />
                                "Live Long and Prosper!"
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default About;
