import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import emailjs from 'emailjs-com';

import GlobalNav from '../components/GlobalNav';

import './Contact.css';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        if (name && email && message && isValidEmail) {
            const serviceId = 'service_mon0lp7';
            const templateId = 'template_jvmqnvw';
            const userId = 'user_0x4KX87aKXdzldiBQm0Lo';
            const templateParams = {
                name,
                email,
                message,
            };

            emailjs
                .send(serviceId, templateId, templateParams, userId)
                .then((response) => console.log(response))
                .then((error) => console.log(error));

            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
        } else {
            alert('Please fill in all fields.');
        }
    };

    const isValidEmail = () => {
        const regex =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };

    const ThankYou = () => {
        return (
            <span className="formText">
                Thank you for your message, we will review your request and
                reach out to you soon.
            </span>
        );
    };

    return (
        <Container fluid>
            <Row noGutters>
                <GlobalNav />
            </Row>
            <Row className="mt-3">
                <Col>
                    <Card bg="dark" text="light" className="p-3">
                        <Card.Title className="cardTitle">
                            General Contact Info
                        </Card.Title>
                        <Card.Body>
                            <ul>
                                <li>Michael's Phone: (702) 234-3813</li>
                                <li>Britt's Phone: (702) 882-9063</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h5>Or, Drop us a line</h5>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="emailForm.name">
                            <Form.Label className="formLabel">
                                Your Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="emailForm.email"
                        >
                            <Form.Label className="formLabel">
                                Email address
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="emailForm.message"
                        >
                            <Form.Label className="formLabel">
                                Message
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Form.Group>
                        <Button onClick={submit}>Send Message</Button>
                        <p />
                        {emailSent ? ThankYou() : null}
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col></Col>
            </Row>
        </Container>
    );
}

export default Contact;
