import React, { FC } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import '../Global.css';
import './GlobalNav.css';

const GlobalNav: FC<{}> = (): JSX.Element => {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <img
                            src="/images/cdlogo64.png"
                            alt="CassidyDesign Logo"
                        />{' '}
                        CassidyDesign
                    </Navbar.Brand>
                </LinkContainer>
            </Container>
            <Container>
                <Navbar.Text>
                    Your Friendly Neighborhood Website Designers
                </Navbar.Text>
            </Container>
            <Container className="justify-content-end">
                <LinkContainer to="/About">
                    <Nav.Link>About</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/Contact">
                    <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
            </Container>
        </Navbar>
    );
};

export default GlobalNav;
