import React from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import GlobalNav from '../components/GlobalNav';

import '../Global.css';

function Home() {
    return (
        <Container fluid>
            <Row noGutters>
                <GlobalNav />
            </Row>
            <Row className="mt-3">
                <Col>
                    <Card bg="dark" text="light" className="text-center">
                        <Card.Body>
                            <Card.Title>Hello World!</Card.Title>
                            <Card.Text>
                                We are a father-daughter dynamic duo
                                specializing in beautifully responsive
                                user-centered website design
                            </Card.Text>
                            <Row>
                                <Col>
                                    <LinkContainer to="/about">
                                        <Button>Who We Are</Button>
                                    </LinkContainer>
                                </Col>
                                <Col>
                                    <LinkContainer to="/contact">
                                        <Button>Say Hi</Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Card bg="dark" text="light" className="text-center">
                        <Card.Body>
                            <Card.Title>Previous Work</Card.Title>
                            <Card.Text>
                                <br />
                                <a
                                    className="textLink"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="http://willowtreerecovery.com"
                                >
                                    Willow Tree Recovery
                                </a>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Card bg="dark" text="light" className="text-center">
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <Card.Text></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
